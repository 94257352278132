/** @jsx jsx */

import { useMemo } from 'react';
import {
  GatsbyTOCItem,
  getHeadings,
  useActiveId,
} from '@untitled-docs/table-of-contents';
import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';

import { FOOTER_HEIGHT, HEADER_HEIGHT } from '../layouts';

export const TableOfContents = ({ toc }: { toc: GatsbyTOCItem }) => {
  const { palette, spacing, typography } = useTheme();

  const headings = useMemo(
    () => getHeadings(toc).filter((item) => item.depth !== 1 && item.depth < 4),
    [toc]
  );

  const activeId = useActiveId(headings);

  if (!headings.length) {
    return null;
  }

  return (
    <aside
      css={{
        boxSizing: 'border-box',
        flexShrink: 0,
        maxHeight: `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT})`,
        paddingTop: spacing.xxlarge,
        overflowY: 'auto',
        paddingLeft: spacing.xlarge,
        position: 'sticky',
        top: HEADER_HEIGHT,
        WebkitOverflowScrolling: 'touch',
        width: 260,
      }}
    >
      <h4
        css={{
          color: palette.text.muted,
          fontSize: typography.fontSize.small,
          fontWeight: 700,
          marginBottom: '1em',
          textTransform: 'uppercase',
        }}
      >
        On this page
      </h4>
      <ul>
        {headings.map((h, i) => {
          let isActive = activeId === h.id;
          return (
            <li
              key={h.id}
              css={{
                // increase specificity to element - avoid `!important` declaration
                // override CSS targeting LI elements from `<Content/>`
                'li&': { lineHeight: 1.4 },
              }}
            >
              <a
                css={{
                  color: isActive
                    ? palette.text.link
                    : h.depth === 2
                    ? palette.text.base
                    : palette.text.muted,
                  display: 'block',
                  fontSize: h.depth === 3 ? '0.8rem' : '0.9rem',
                  fontWeight: isActive ? 500 : 'normal',
                  paddingLeft: h.depth === 3 ? '0.5rem' : undefined,
                  textDecoration: 'none',

                  // prefer padding an anchor, rather than margin on list-item, to increase hit area
                  paddingBottom: '0.4em',
                  paddingTop: '0.4em',

                  ':hover': {
                    color: palette.text.link,
                  },
                }}
                href={`#${h.id}`}
              >
                {h.value}
              </a>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};
