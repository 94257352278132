/** @jsx jsx */

import { Fragment } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { jsx } from '@balance-web/core';

import { DocPageQuery } from '../../graphql-types';
import { TableOfContents } from '../components/TableOfContents';
import { Container } from '../components/Container';
import { MdxWithTitle } from '../components/Mdx';

const PackagePage = ({ data }: { data: DocPageQuery }) => {
  const title =
    data.mdx!.headings && data.mdx!.headings[0]
      ? data.mdx!.headings[0].value
      : 'Package';

  return (
    <Fragment>
      <Helmet>
        <title>{`${title} - Balance Design System`}</title>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Container>
        <div css={{ display: 'flex', flexDirection: 'row' }}>
          <main css={{ flex: 1, minWidth: 0, paddingBottom: 48 }}>
            <MdxWithTitle>{data.mdx!.body}</MdxWithTitle>
          </main>
          <TableOfContents toc={data.mdx!.tableOfContents} />
        </div>
      </Container>
    </Fragment>
  );
};

export const pageQuery = graphql`
  query DocPage($id: String!) {
    mdx(id: { eq: $id }) {
      body
      tableOfContents
      headings(depth: h1) {
        value
      }
    }
  }
`;

export default PackagePage;
